// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "trix"
import "@rails/actiontext"
import * as bootstrap from "bootstrap"
import "./controllers"
import flatpickr from "flatpickr"
import "flatpickr/dist/flatpickr.css"

var jQuery = require("jquery");
window.$ = window.jQuery = jQuery;
window.geo_location = undefined;

$(function() {

    $(".progress").each(function() {
        var value = $(this).attr('data-value');
        var left = $(this).find('.progress-left .progress-bar');
        var right = $(this).find('.progress-right .progress-bar');

        if (value > 0) {
            if (value <= 50) {
                right.css('transform', 'rotate(' + percentageToDegrees(value) + 'deg)')
            } else {
                right.css('transform', 'rotate(180deg)')
                left.css('transform', 'rotate(' + percentageToDegrees(value - 50) + 'deg)')
            }
        }
    })

    function percentageToDegrees(percentage) {
        return percentage / 100 * 360
    }

    function readURL(input) {
        if (input.files && input.files[0]) {
            let reader = new FileReader();
            reader.onload = function (e) {
                input.nextElementSibling.setAttribute('src', e.target.result)
            }
            reader.readAsDataURL(input.files[0]);
        }
    }

    $(".profileImages").change(function(){
        readURL(this);
    });

});

console.log('Hello World from application.js');

function toggleElement(element) {
    document.getElementById(element).classList.toggle('d-none');
};

window.toggleElement = toggleElement;

function selectAllMembers() {
    let checkboxes = document.querySelectorAll('.member-checkbox');
    checkboxes.forEach(checkbox => {
        checkbox.checked = true;
    });
    document.getElementById('selectAllMembersButton').classList.add('d-none');
    document.getElementById('deselectAllMembersButton').classList.remove('d-none');
}
window.selectAllMembers = selectAllMembers;

function deselectAllMembers() {
    let checkboxes = document.querySelectorAll('.member-checkbox');
    checkboxes.forEach(checkbox => {
        checkbox.checked = false;
    });
    document.getElementById('deselectAllMembersButton').classList.add('d-none');
    document.getElementById('selectAllMembersButton').classList.remove('d-none');
}
window.deselectAllMembers = deselectAllMembers;

$(document).on('turbolinks:load', function() {
    initMap();
});