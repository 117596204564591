import { Controller } from "@hotwired/stimulus"
import Cropper from "cropperjs";
import * as bootstrap from "bootstrap";
import {FetchRequest} from "@rails/request.js";

let cropper;

export default class extends Controller {
    static targets = [
        "sourceImage",
        "ppInput",
        "cropperModal",
        "member"
    ]

    connect() {
        console.log("##### Cropper controller > connect")
    }

    displayCropperModal() {
        let myModal = new bootstrap.Modal(document.getElementById('cropProfilePhoto'), {});
        myModal.show()
        setTimeout(this.createCropper, 500)
    }

    createCropper() {
        const image = document.getElementById('cropperSourceImage');

        cropper = new Cropper(image, {
            aspectRatio: 1,
            dragMode: 'move',
            viewMode: 3,
            autoCropArea: 1,
            restore: false,
            modal: true,
            guides: true,
            center: false,
            background: false,
            highlight: true,
            cropBoxMovable: true,
            cropBoxResizable: false,
            toggleDragModeOnDblclick: false,
            checkCrossOrigin: false,
            crop(event) {
                console.log("##### Cropper > createCropper > crop")
            },
        });

    }

    async saveCroppedPhoto({ params: { source } }) {
        console.log("##### source > " + source)
        let croppedData = cropper.getCroppedCanvas({
            width: 200,
            height: 200,
            fillColor: '#fff',
            imageSmoothingEnabled: false,
            imageSmoothingQuality: 'high'
        }).toDataURL('image/jpeg');

        const memberId = document.getElementById('memberIdValue').value

        const formData = new FormData();
        formData.append("member[profile_pic]", this.base64ToBlob(croppedData), memberId + "_cropped_pp.jpg");

        let url = '/admin/members/' + memberId
        let method = "patch"

        if (source === "profile") {
            formData.append("id", memberId);
            url = '/profile'
            method = "post"
        }
        console.log("##### url > " + url)
        const req = new FetchRequest(method, url, {
            responseKind: "json",
            body: formData
        });
        const response = await req.perform();
        const data = await response.json;

        if (response.response.status === 422){
            console.log("ERROR")
        } else {
            console.log("SUCCESS")
        }
        document.location.reload();
    }

    cropperZoomIn() {
        cropper.zoom(0.03);
    }

    cropperZoomOut() {
        cropper.zoom(-0.03);
    }

    cropperZoomFit() {
        cropper.zoomTo(0);
    }

    base64ToBlob(base64Data) {
        const byteString = atob(base64Data.split(',')[1]);
        const mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0];

        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], { type: mimeString });
    }

}