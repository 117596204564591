import {Controller} from "@hotwired/stimulus"
import {FetchRequest} from "@rails/request.js"

let location = undefined;

// Connects to data-controller="member-search"
export default class extends Controller {
    static targets = ["addressInput", "submitButton"]
    static values = {locurl: String}

    async connect() {

        if ('geolocation' in navigator) {
            if (window.geo_location === undefined) {
                let _parentThis = this;
                function geo_success(position) {

                    console.log("### geo_success: " + JSON.stringify(position.toJSON()) )
                    console.log("### latitude: " + position.coords.latitude )
                    console.log("### longitude: " + position.coords.longitude )

                    _parentThis.getLocationFromBrowser(position.coords.latitude, position.coords.longitude)
                        .then((d) => {
                            console.log("### d.result[0].data.address['City']: " + d.result[0].data.address['City'] )
                            var est_adress = d.result[0].data.address['City'] + ', ' + d.result[0].data.address['Region'] + ', ' + d.result[0].data.address['CntryName']
                            console.log("### geo_success: " + d.est_adress)
                            window.geo_location = est_adress
                            _parentThis.addressInputTarget.value = est_adress
                        })
                }

                function geo_error() {
                    console.log('geo permission denied')
                    window.geo_location = false;
                    _parentThis.getLocationFromBrowser().then((d) => {
                        let data = d.result[0].data;
                        _parentThis.addressInputTarget.value = `${data.city}, ${data.region}, ${data.country}`
                    })
                    console.log("### geo_error: " + d)
                    // this.submitButtonTarget.click()
                }

                navigator.geolocation.getCurrentPosition(geo_success, geo_error)

            } else {
                _parentThis.addressInputTarget.value = window.geo_location
            }
        } else {
            console.log('geo location is not found')
            /* geolocation IS NOT available */
        }
    }


    async getLocationFromBrowser(lat, log) {
        // if (!lat || !log) return

        const req = new FetchRequest("post", this.locurlValue, {
            body: JSON.stringify({
                lat: lat,
                lon: log,
            }),
            contentType: "application/json",
            responseKind: "json"
        });
        const response = await req.perform();
        return await response.json;
    }
}
